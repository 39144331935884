/* syne-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/syne-v22-latin/syne-v22-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Syne";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/syne-v22-latin/syne-v22-latin-500.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/syne-v22-latin/syne-v22-latin-600.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/syne-v22-latin/syne-v22-latin-700.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-800 - latin */
@font-face {
  font-display: swap;
  font-family: "Syne";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/syne-v22-latin/syne-v22-latin-800.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.fontSyne {
  font-family: "Syne";
}
