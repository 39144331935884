/* overpass-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/overpass-v13-latin/overpass-v13-latin-regular.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/overpass-v13-latin/overpass-v13-latin-700.woff2")
    format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.fontOverpass {
  font-family: "Overpass";
}
