/* You can add global styles to this file, and also import other style files */
@import "./../src/assets/styles/syne.scss";
@import "./../src/assets/styles/overpass.scss";

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: rgb(88, 77, 100); // developer
  // background-color: rgb(255, 252, 242); // original
}

.disableTextSelection {
  user-select: none; /* Für Webkit-Browser wie Chrome, Safari */
  -webkit-user-select: none; /* Für Webkit-Browser */
  -moz-user-select: none; /* Für Firefox */
  -ms-user-select: none; /* Für Internet Explorer */
}